import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import 'intersection-observer';
import Observer from '@researchgate/react-intersection-observer';
import TransitionLink from 'gatsby-plugin-transition-link';
import Layout from '../../components/layout';
import Transition from '../../components/transition';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { InitTransition } from '../../components/init-transition';
import InitAnimation from '../../components/init-animation';
import Style from '../../styles/ourteam/ourteam.module.scss';
import icon_link from '../../images/common/icon_sns_link.svg';
import icon_fb from '../../images/common/icon_sns_fb.svg';
import icon_instagram from '../../images/common/icon_sns_instagram.svg';
import icon_tumblr from '../../images/common/icon_sns_tumblr.svg';

class IntroducingOurTeam extends React.Component {
  constructor() {
    super();
    this.child = React.createRef();
    this.initAnimation = new InitAnimation();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const headingOurteam = document.querySelector('.init_a_headingOurteam');
    const introOurteam = document.querySelector('.init_a_introOurteam');
    setTimeout(() => {
      this.initAnimation.heading(headingOurteam);
      this.initAnimation.intro(introOurteam);
    }, 1000);
  }

  member = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const ourTeamItemBg = document.querySelectorAll('.init_a_memberItem_bg');
      const ourTeamItemImg = document.querySelectorAll('.init_a_memberItem_img');
      const ourTeamItemNameBg = document.querySelectorAll('.init_a_memberItemName_bg');
      const ourTeamItemNameText = document.querySelectorAll('.init_a_memberItemName_text');
      const ourTeamItemProfessionBg = document.querySelectorAll('.init_a_memberItemProfession_bg');
      const ourTeamItemProfessionText = document.querySelectorAll('.init_a_memberItemProfession_text');
      const ourTeamItemSnsBg = document.querySelectorAll('.init_a_memberItemSns_bg');
      const ourTeamItemSnsImg = document.querySelectorAll('.init_a_memberItemSns_img');
      this.initAnimation.slider(ourTeamItemBg, ourTeamItemImg);
      this.initAnimation.slider(ourTeamItemNameBg, ourTeamItemNameText);
      this.initAnimation.slider(ourTeamItemProfessionBg, ourTeamItemProfessionText);
      this.initAnimation.slider(ourTeamItemSnsBg, ourTeamItemSnsImg);
      unobserve();
    }
  };

  // joinOurTeam = ({ isIntersecting }, unobserve) => {
  //   if (isIntersecting) {
  //     const isJoinOurTeam = 'JoinOurTeam';
  //     const headingValueToGive = document.querySelector('.init_a_headingJoinOurTeam');
  //     const introJoinOurTeam = document.querySelector('.init_a_introJoinOurTeam');
  //     this.initAnimation.heading(headingValueToGive, isJoinOurTeam);
  //     this.initAnimation.intro(introJoinOurTeam);
  //     unobserve();
  //   }
  // };

  render() {
    const OurteamQuery = graphql`
      {
        illustration: file(relativePath: { eq: "illustration/illustration03.png" }){
          childImageSharp {
            fluid(maxWidth: 911, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        member01: file(relativePath: { eq: "ourteam/takahiro_mitsukawa/thumbnail.jpg" }){
          childImageSharp {
            fluid(maxWidth: 460, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        member03: file(relativePath: { eq: "ourteam/hiroki_ueda/thumbnail.jpg" }){
          childImageSharp {
            fluid(maxWidth: 460, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        member04: file(relativePath: { eq: "ourteam/yuka_kanehara/thumbnail.jpg" }){
          childImageSharp {
            fluid(maxWidth: 460, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        member05: file(relativePath: { eq: "ourteam/hajime_ota/thumbnail.jpg" }){
          childImageSharp {
            fluid(maxWidth: 460, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        member06: file(relativePath: { eq: "ourteam/kazuma_hitomi/thumbnail.jpg" }){
          childImageSharp {
            fluid(maxWidth: 460, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        member07: file(relativePath: { eq: "ourteam/kento_yoshida/thumbnail.jpg" }){
          childImageSharp {
            fluid(maxWidth: 460, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        member08: file(relativePath: { eq: "ourteam/mayu_hasegawa/thumbnail.jpg" }){
          childImageSharp {
            fluid(maxWidth: 460, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        member09: file(relativePath: { eq: "ourteam/huyuho_kawai/thumbnail.jpg" }){
          childImageSharp {
            fluid(maxWidth: 460, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        member10: file(relativePath: { eq: "ourteam/yumi_hayashi/thumbnail.jpg" }){
          childImageSharp {
            fluid(maxWidth: 460, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        member11: file(relativePath: { eq: "ourteam/mai_saruta/thumbnail.jpg" }){
          childImageSharp {
            fluid(maxWidth: 460, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        member12: file(relativePath: { eq: "ourteam/satoshi_terada/thumbnail.jpg" }){
          childImageSharp {
            fluid(maxWidth: 460, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        member13: file(relativePath: { eq: "ourteam/narumi_wakabayashi/thumbnail.jpg" }){
          childImageSharp {
            fluid(maxWidth: 460, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        member14: file(relativePath: { eq: "ourteam/reia_kubota/thumbnail.jpg" }){
          childImageSharp {
            fluid(maxWidth: 460, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
      }
    `;

    const transition = {
      InitTransition,
      animation: () => {
        this.child.current.exitAnimation(transition.InitTransition.length);
      },
    };

    const options = {
      root: null,
      rootMargin: '-50% 0px',
      threshold: 0,
    };


    // const optionsWFANE = {
    //   root: null,
    //   rootMargin: '-50px',
    //   threshold: 0,
    // };

    return (
      <StaticQuery
        query={OurteamQuery}
        render={(data) => (
          <Layout>
            <Transition ref={this.child}>
              <Header transition={transition} current="ourteam" />
              <ParallaxProvider>
                <main className={`ourteam ${Style.ourteam}`}>
                  <section className={`top ${Style.top}`}>
                    <div className={Style.left}>
                      <h1 className="heading">
                        <span className="text">Introducing<br className="spTabHDisBlock" /> our team</span>
                        <span className="clip init_a_headingOurteam">Introducing<br className="spTabHDisBlock" /> our team</span>
                      </h1>
                      <p className="intro init_a_introOurteam">私たちの職能は「属人化した能力である」という考えを意識的に認め、とことん個人の成長に向き合ったチームを目指しています。</p>
                    </div>
                    <Parallax className={Style.illustration} x={[10, -10]} tagInner="figure">
                      <Img
                        className={Style.illustrationImg}
                        fluid={data.illustration.childImageSharp.fluid}
                        alt="Introducing our team"
                      />
                    </Parallax>
                  </section>
                  <Observer {...options} onChange={this.member}>
                    <section className={`sectionContent sectionContentFirst ${Style.member}`}>
                      <div className="scroll scrollTop" />
                      <div className={`inner ${Style.inner}`}>
                        <ul className={Style.memberList}>
                          <li className={`${Style.memberItem}`}>
                            {/*<TransitionLink to="/ourteam/takahiro-mitsukawa" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>*/}
                            <div className="init_a_memberItem">
                              <div className="init_a_memberItem_bg" />
                              <Img
                                className="face init_a_memberItem_img"
                                fluid={data.member01.childImageSharp.fluid}
                                alt="光川貴浩"
                              />
                            </div>
                            <div className={`init_a_memberItemName ${Style.name}`}>
                              <div className="init_a_memberItemName_bg" />
                              <p className={`init_a_memberItemName_text ${Style.nameText}`}>光川貴浩</p>
                            </div>
                            <div className={`init_a_memberItemProfession ${Style.profession}`}>
                              <div className="init_a_memberItemProfession_bg" />
                              <p className={`init_a_memberItemProfession_text ${Style.professionText}`}>代表／編集者／路地研究家／<br />京都精華大学 非常勤講師／<br />京都芸術大学 非常勤講師</p>
                            </div>
                            <div className={`init_a_memberItemSns`}>
                              <div className='init_a_memberItemSns_bg' />
                              <ul>
                                <li className={`init_a_memberItemSns_img`}>
                                  <a href="https://www.facebook.com/takahiro.mitsukawa.5" target="_blank">
                                    <img src={icon_fb} alt="facebook"/>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            {/*</TransitionLink>*/}
                          </li>
                          <li className={`${Style.memberItem}`}>
                            {/*<TransitionLink to="/ourteam/takahiro-mitsukawa" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>*/}
                            <div className="init_a_memberItem">
                              <div className="init_a_memberItem_bg" />
                              <Img
                                className="face init_a_memberItem_img"
                                fluid={data.member03.childImageSharp.fluid}
                                alt="上田広樹"
                              />
                            </div>
                            <div className={`init_a_memberItemName ${Style.name}`}>
                              <div className="init_a_memberItemName_bg" />
                              <p className={`init_a_memberItemName_text ${Style.nameText}`}>上田広樹</p>
                            </div>
                            <div className={`init_a_memberItemProfession ${Style.profession}`}>
                              <div className="init_a_memberItemProfession_bg" />
                              <p className={`init_a_memberItemProfession_text ${Style.professionText}`}>デジタルクリエイター</p>
                            </div>
                            {/*</TransitionLink>*/}
                          </li>
                          <li className={`${Style.memberItem}`}>
                            {/*<TransitionLink to="/ourteam/takahiro-mitsukawa" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>*/}
                            <div className="init_a_memberItem">
                              <div className="init_a_memberItem_bg" />
                              <Img
                                className="face init_a_memberItem_img"
                                fluid={data.member04.childImageSharp.fluid}
                                alt="金原由佳"
                              />
                            </div>
                            <div className={`init_a_memberItemName ${Style.name}`}>
                              <div className="init_a_memberItemName_bg" />
                              <p className={`init_a_memberItemName_text ${Style.nameText}`}>金原由佳</p>
                            </div>
                            <div className={`init_a_memberItemProfession ${Style.profession}`}>
                              <div className="init_a_memberItemProfession_bg" />
                              <p className={`init_a_memberItemProfession_text ${Style.professionText}`}>デザイナー</p>
                            </div>
                            <div className={`init_a_memberItemSns`}>
                              <div className='init_a_memberItemSns_bg' />
                              <ul>
                                <li className={`init_a_memberItemSns_img`}>
                                  <a href="https://www.instagram.com/cane_illust/" target="_blank">
                                    <img src={icon_instagram} alt="instagram"/>
                                  </a>
                                </li>
                                <li className={`init_a_memberItemSns_img`}>
                                  <a href="https://kaneharayuka.tumblr.com/" target="_blank">
                                  <img src={icon_tumblr} alt="tumblr"/>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            {/*</TransitionLink>*/}
                          </li>
                          <li className={`${Style.memberItem}`}>
                            {/*<TransitionLink to="/ourteam/takahiro-mitsukawa" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>*/}
                            <div className="init_a_memberItem">
                              <div className="init_a_memberItem_bg" />
                              <Img
                                className="face init_a_memberItem_img"
                                fluid={data.member05.childImageSharp.fluid}
                                alt="太田孟"
                              />
                            </div>
                            <div className={`init_a_memberItemName ${Style.name}`}>
                              <div className="init_a_memberItemName_bg" />
                              <p className={`init_a_memberItemName_text ${Style.nameText}`}>太田孟</p>
                            </div>
                            <div className={`init_a_memberItemProfession ${Style.profession}`}>
                              <div className="init_a_memberItemProfession_bg" />
                              <p className={`init_a_memberItemProfession_text ${Style.professionText}`}>ディレクター</p>
                            </div>
                            <div className={`init_a_memberItemSns`}>
                              <div className='init_a_memberItemSns_bg' />
                              <ul>
                                <li className={`init_a_memberItemSns_img`}>
                                  <a href="https://kibi-tsuki.jp" target="_blank">
                                    <img src={icon_link} alt="link"/>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            {/*</TransitionLink>*/}
                          </li>
                          <li className={`${Style.memberItem}`}>
                            {/*<TransitionLink to="/ourteam/takahiro-mitsukawa" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>*/}
                            <div className="init_a_memberItem">
                              <div className="init_a_memberItem_bg" />
                              <Img
                                className="face init_a_memberItem_img"
                                fluid={data.member06.childImageSharp.fluid}
                                alt="人見和真"
                              />
                            </div>
                            <div className={`init_a_memberItemName ${Style.name}`}>
                              <div className="init_a_memberItemName_bg" />
                              <p className={`init_a_memberItemName_text ${Style.nameText}`}>人見和真</p>
                            </div>
                            <div className={`init_a_memberItemProfession ${Style.profession}`}>
                              <div className="init_a_memberItemProfession_bg" />
                              <p className={`init_a_memberItemProfession_text ${Style.professionText}`}>テクニカルディレクター／<br />デザインエンジニア</p>
                            </div>
                            {/*</TransitionLink>*/}
                          </li>
                          <li className={`${Style.memberItem}`}>
                            {/*<TransitionLink to="/ourteam/takahiro-mitsukawa" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>*/}
                            <div className="init_a_memberItem">
                              <div className="init_a_memberItem_bg" />
                              <Img
                                className="face init_a_memberItem_img"
                                fluid={data.member07.childImageSharp.fluid}
                                alt="吉田健人"
                              />
                            </div>
                            <div className={`init_a_memberItemName ${Style.name}`}>
                              <div className="init_a_memberItemName_bg" />
                              <p className={`init_a_memberItemName_text ${Style.nameText}`}>吉田健人</p>
                            </div>
                            <div className={`init_a_memberItemProfession ${Style.profession}`}>
                              <div className="init_a_memberItemProfession_bg" />
                              <p className={`init_a_memberItemProfession_text ${Style.professionText}`}>アートディレクター／デザイナー<br />京都精華大学 非常勤講師</p>
                            </div>
                            <div className={`init_a_memberItemSns`}>
                              <div className='init_a_memberItemSns_bg' />
                              <ul>
                                <li className={`init_a_memberItemSns_img`}>
                                  <a href="https://bankto-yoshida.tumblr.com/" target="_blank">
                                    <img src={icon_tumblr} alt="tumblr"/>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            {/*</TransitionLink>*/}
                          </li>
                          <li className={`${Style.memberItem}`}>
                            {/*<TransitionLink to="/ourteam/takahiro-mitsukawa" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>*/}
                            <div className="init_a_memberItem">
                              <div className="init_a_memberItem_bg" />
                              <Img
                                className="face init_a_memberItem_img"
                                fluid={data.member08.childImageSharp.fluid}
                                alt="長谷川茉由"
                              />
                            </div>
                            <div className={`init_a_memberItemName ${Style.name}`}>
                              <div className="init_a_memberItemName_bg" />
                              <p className={`init_a_memberItemName_text ${Style.nameText}`}>長谷川茉由</p>
                            </div>
                            <div className={`init_a_memberItemProfession ${Style.profession}`}>
                              <div className="init_a_memberItemProfession_bg" />
                              <p className={`init_a_memberItemProfession_text ${Style.professionText}`}>ディレクター</p>
                            </div>
                            {/*</TransitionLink>*/}
                          </li>
                          <li className={`${Style.memberItem}`}>
                            {/*<TransitionLink to="/ourteam/takahiro-mitsukawa" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>*/}
                            <div className="init_a_memberItem">
                              <div className="init_a_memberItem_bg" />
                              <Img
                                className="face init_a_memberItem_img"
                                fluid={data.member09.childImageSharp.fluid}
                                alt="河井冬穂"
                              />
                            </div>
                            <div className={`init_a_memberItemName ${Style.name}`}>
                              <div className="init_a_memberItemName_bg" />
                              <p className={`init_a_memberItemName_text ${Style.nameText}`}>河井冬穂</p>
                            </div>
                            <div className={`init_a_memberItemProfession ${Style.profession}`}>
                              <div className="init_a_memberItemProfession_bg" />
                              <p className={`init_a_memberItemProfession_text ${Style.professionText}`}>チーフディレクター</p>
                            </div>
                            {/*</TransitionLink>*/}
                          </li>
                          <li className={`${Style.memberItem}`}>
                            {/*<TransitionLink to="/ourteam/takahiro-mitsukawa" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>*/}
                            <div className="init_a_memberItem">
                              <div className="init_a_memberItem_bg" />
                              <Img
                                className="face init_a_memberItem_img"
                                fluid={data.member10.childImageSharp.fluid}
                                alt="早志祐美"
                              />
                            </div>
                            <div className={`init_a_memberItemName ${Style.name}`}>
                              <div className="init_a_memberItemName_bg" />
                              <p className={`init_a_memberItemName_text ${Style.nameText}`}>早志祐美</p>
                            </div>
                            <div className={`init_a_memberItemProfession ${Style.profession}`}>
                              <div className="init_a_memberItemProfession_bg" />
                              <p className={`init_a_memberItemProfession_text ${Style.professionText}`}>ディレクター</p>
                            </div>
                            {/*</TransitionLink>*/}
                          </li>
                          <li className={`${Style.memberItem}`}>
                            {/*<TransitionLink to="/ourteam/takahiro-mitsukawa" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>*/}
                            <div className="init_a_memberItem">
                              <div className="init_a_memberItem_bg" />
                              <Img
                                className="face init_a_memberItem_img"
                                fluid={data.member11.childImageSharp.fluid}
                                alt="猿田真維"
                              />
                            </div>
                            <div className={`init_a_memberItemName ${Style.name}`}>
                              <div className="init_a_memberItemName_bg" />
                              <p className={`init_a_memberItemName_text ${Style.nameText}`}>猿田真維</p>
                            </div>
                            <div className={`init_a_memberItemProfession ${Style.profession}`}>
                              <div className="init_a_memberItemProfession_bg" />
                              <p className={`init_a_memberItemProfession_text ${Style.professionText}`}>デザイナー</p>
                            </div>
                            <div className={`init_a_memberItemSns`}>
                              <div className='init_a_memberItemSns_bg' />
                              <ul>
                                <li className={`init_a_memberItemSnser`}>
                                  <a href="https://srtmi.portfoliobox.net/" target="_blank">
                                    <img src={icon_link} alt="link"/>
                                  </a>
                                </li>
                              </ul>
                            </div>

                            {/*</TransitionLink>*/}
                          </li>
                          <li className={`${Style.memberItem}`}>
                            {/*<TransitionLink to="/ourteam/takahiro-mitsukawa" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>*/}
                            <div className="init_a_memberItem">
                              <div className="init_a_memberItem_bg" />
                              <Img
                                className="face init_a_memberItem_img"
                                fluid={data.member12.childImageSharp.fluid}
                                alt="寺田智嗣"
                              />
                            </div>
                            <div className={`init_a_memberItemName ${Style.name}`}>
                              <div className="init_a_memberItemName_bg" />
                              <p className={`init_a_memberItemName_text ${Style.nameText}`}>寺田智嗣</p>
                            </div>
                            <div className={`init_a_memberItemProfession ${Style.profession}`}>
                              <div className="init_a_memberItemProfession_bg" />
                              <p className={`init_a_memberItemProfession_text ${Style.professionText}`}>テクニカルディレクター／<br />フロントエンドエンジニア</p>
                            </div>
                            {/*</TransitionLink>*/}
                          </li>
                          <li className={`${Style.memberItem}`}>
                            {/*<TransitionLink to="/ourteam/takahiro-mitsukawa" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>*/}
                            <div className="init_a_memberItem">
                              <div className="init_a_memberItem_bg" />
                              <Img
                                className="face init_a_memberItem_img"
                                fluid={data.member13.childImageSharp.fluid}
                                alt="若林成実"
                              />
                            </div>
                            <div className={`init_a_memberItemName ${Style.name}`}>
                              <div className="init_a_memberItemName_bg" />
                              <p className={`init_a_memberItemName_text ${Style.nameText}`}>若林成実</p>
                            </div>
                            <div className={`init_a_memberItemProfession ${Style.profession}`}>
                              <div className="init_a_memberItemProfession_bg" />
                              <p className={`init_a_memberItemProfession_text ${Style.professionText}`}>テクニカルディレクター／<br />フロントエンドエンジニア</p>
                            </div>
                            {/*</TransitionLink>*/}
                          </li>
                          <li className={`${Style.memberItem}`}>
                            {/*<TransitionLink to="/ourteam/takahiro-mitsukawa" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>*/}
                            <div className="init_a_memberItem">
                              <div className="init_a_memberItem_bg" />
                              <Img
                                className="face init_a_memberItem_img"
                                fluid={data.member14.childImageSharp.fluid}
                                alt="窪田令亜"
                              />
                            </div>
                            <div className={`init_a_memberItemName ${Style.name}`}>
                              <div className="init_a_memberItemName_bg" />
                              <p className={`init_a_memberItemName_text ${Style.nameText}`}>窪田令亜</p>
                            </div>
                            <div className={`init_a_memberItemProfession ${Style.profession}`}>
                              <div className="init_a_memberItemProfession_bg" />
                              <p className={`init_a_memberItemProfession_text ${Style.professionText}`}>ディレクター</p>
                            </div>
                            {/*</TransitionLink>*/}
                          </li>
                        </ul>
                      </div>
                    </section>
                  </Observer>
                  {/*<Observer {...optionsWFANE} onChange={this.joinOurTeam}>*/}
                  {/*  <section className={`sectionContent ${Style.joinOurTeam}`}>*/}
                  {/*    <h2 className="heading">*/}
                  {/*      <span className="text textJoinOurTeam">Waiting for <br />a new encounter</span>*/}
                  {/*      <span className="clip clipJoinOurTeam init_a_headingJoinOurTeam">Waiting for <br />a new encounter</span>*/}
                  {/*    </h2>*/}
                  {/*    <p className="intro init_a_introJoinOurTeam">*/}
                  {/*      碁盤の目の街を縫うように、路地がひしめく京都。*/}
                  {/*      <br className="pcDisBlock" />*/}
                  {/*      その数、約13,000本とも。*/}
                  {/*      <br className="pcDisBlock" />*/}
                  {/*      そこには観光とは無縁の、京都の素顔が広がっています。*/}
                  {/*      <br className="pcDisBlock" />*/}
                  {/*      Googleでさえ、知らない世界へ。*/}
                  {/*    </p>*/}
                  {/*    <p className={`btn ${Style.btn}`}>*/}
                  {/*      <TransitionLink to="/recruit" exit={{ length: transition.length, trigger: () => transition.animation() }} entry={{ delay: transition.delay }}>*/}
                  {/*        <span>Join our team</span>*/}
                  {/*      </TransitionLink>*/}
                  {/*    </p>*/}
                  {/*  </section>*/}
                  {/*</Observer>*/}
                </main>
              </ParallaxProvider>
              <Footer transition={transition} />
            </Transition>
          </Layout>
        )}
      />
    );
  }
}

export default IntroducingOurTeam;
